// 이벤트 설정 참고
// https://docs.google.com/spreadsheets/d/1d7kdRm0mXD8K0h7UF2HDWsfeDCRUOgX-J0jhFoZVtq8/edit?usp=sharing

/**
 * gtag sender
 */
function gtag() {
  let length = window.dataLayer.push(arguments);
  if (GAReporter.debugMode) {
    console.log(length," events pushed")
  }
}

/**
 * 구글 애널리틱스 설정 코드 (모든 페이지에 삽입)
 * @param {String} gaId
 * @param {Number} userId
 * @param purchasedCategory
 * @param {Boolean} debug
 */
function setupGA(gaId, userId, purchasedCategory, debug) {
  let uid;
  let isSvUser = false;
  let dsvUser = false;
  if (userId && userId.toString().startsWith('c') && /c\d+/.test(userId.toString()) == false) {
    uid = null;
  }
  if (purchasedCategory) { // ["pt", "dsv", "sv"]
    try {
      if (!Array.isArray(purchasedCategory)) {
        purchasedCategory = purchasedCategory.toString().split(",")
      }
      isSvUser = purchasedCategory.filter((e)=> e == "sv").length > 0
      dsvUser = purchasedCategory.filter((e)=> e == "dsv").length > 0
    } catch (e) {}
  }
  let debugMode = debug || false;
  if (debugMode) {
    console.log("GA 디버그 모드 -- " +debugMode,"/ userId: " + userId, "/ gaId :" + gaId, "/svUser:", isSvUser );
    console.log("purchasedCategory: ", purchasedCategory);
  }
  GAReporter.debugMode = debugMode;
  window.dataLayer = window.dataLayer || [];
  
  if (GAReporter.__getEnv() == 'web') {
    gtag('js', new Date());
    gtag('config', gaId, {
      'debug_mode': debugMode,
      'user_id': uid,
      'user_properties': {
        'sport_voucher_used': isSvUser,
        'sport_d_voucher_used': dsvUser,
      }
    });
  } else if (uid) {
    GAReporter.__setUserPropertyToApp('sport_voucher_used', isSvUser);
    GAReporter.__setUserPropertyToApp('sport_d_voucher_used', dsvUser);
  }
}

/**
 * 상품 대분류
 */
const GAProductCategory = {
  OnlinePT: "class",
  Plus: "plus",
  SportVoucher: "s_voucher",
  D_SportVoucher: "d_voucher"
}

/**
 * 상품 전시 분류
 */
const GAProductListCategory = {
  OnlinePTList : "pt_products",
  PlusList: "plus_products",
  VoucherList: "voucher_products",
  DVoucherList: "d_voucher_products",
  TrialPTList: "trial_pt_products",
  DetailPage: "detail_page"
}

class GAEcommerceProductList {
  /**
   * 상품 리스트 구분 생성
   * @param {GAProductListCategory} category
   */
  constructor(category) {
    this.category = category;
  }

  get name() {
    switch (this.category) {
      case GAProductListCategory.OnlinePTList: return "온라인PT";
      case GAProductListCategory.PlusList: return "에드핏플러스";
      case GAProductListCategory.VoucherList: return "스강";
      case GAProductListCategory.DVoucherList: return "장스강";
      case GAProductListCategory.TrialPTList: return "무료 체험";
      case GAProductListCategory.DetailPage: return "클래스 상세화면";
      default: return "--";
    }
  }

  get id() {
    return this.category
  }
}

class GAReport {
  /**
   * GA 데이터 형식
   * @param {String} event
   * @param {Object} data
   */
  constructor(event, data) {
    this.event = event
    this.data = data
  }

}

/**
 * 채팅 관련
 */

class ChatReport extends GAReport {
  static userChat_send(calleeId, messageType) {
    return new SurveyReport("web_user_chat_send",
      { "callee_id": calleeId, "messageType": messageType });
  }
  static userCall_begin(calleeId) {
    return new SurveyReport("web_user_call_begin", { "callee_id": calleeId });
  }
  static userCall_complete(calleeId) {
    return new SurveyReport("web_user_call_complete", { "callee_id": calleeId });
  }
  static coachChat_send(calleeId, messageType) {
    return new SurveyReport("web_coach_chat_send",
      { "callee_id": calleeId, "messageType": messageType });
  }
  static coachCall_begin(calleeId) {
    return new SurveyReport("web_coach_call_begin", { "callee_id": calleeId });
  }
  static coachCall_complete(calleeId) {
    return new SurveyReport("web_coach_call_complete", { "callee_id": calleeId });
  }
}

/**
 * 설문 관련 보고
 */
class SurveyReport extends GAReport {
  static surveyOnBoarding_open() {
    return new SurveyReport("survey_onboarding_open", null);
  }
  static clickStartSurveyDiagnosisFromProgram() {
    return new SurveyReport("click_start_survey_diagnosis_from_program", null);
  }
  
  /**
   * 성향+진단 설문 시작
   * @returns {SurveyReport}
   */
  static surveyStartView() {
    return new SurveyReport("survey_start_view", null);
  }
  
  /**
   * @param referer home, program
   * @returns {SurveyReport}
   */
  static survey2_begin(referer) {
    return new SurveyReport("survey2_begin", { "referer": referer });
  }
  /**
   * @param {Number} page 1~n
   */
  static survey2_view(page) {
    return new SurveyReport("survey2_view", { "page": page });
  }
  
  static survey2_resulting() {
    return new SurveyReport("survey2_resulting", null);
  }
  
  static survey2_resultView() {
    return new SurveyReport("survey2_result_view", null);
  }
  
  static survey2_chat() {
    return new SurveyReport("survey2_chat", null);
  }
  
  static surveyOnboarding_login() {
    return new SurveyReport("survey_onboarding_login", null);
  }
  
  static clickMy_coach_suggestion() {
    return new SurveyReport("click_my_coach_suggestion", null)
  }
  
  static clickMy_physical_analysis() {
    return new SurveyReport("click_my_physical_analysis", null)
  }
}





/**
 * GA 이벤트와 데이터 작성
 */
const GAReporter = {

  debugMode: false,

  /**
   * GAReport 형식을 전송
   * @param {Function} sender
   * @param {GAReport} report
   */
  report(sender, report) {
    this.__log(sender, report.event, report.data)
  },

  /**
   * @param {Function} sender
   * @param {number} userClassId
   * @param {number} workoutDay 1~n
   */
  submitWeightComplete(sender, userClassId, workoutDay) {
    this.__log(sender, "web_submit_weight_complete", {
      "ucid": userClassId,
      "workout_day": workoutDay
    });
  },
  /**
   * @param {Function} sender
   * @param {number} userClassId
   * @param {number} workoutDay 1~n
   */
  submitHomeworkComplete(sender, userClassId, workoutDay) {
    this.__log(sender, "web_submit_homework_complete", {
      "ucid": userClassId,
      "workout_day": workoutDay
    });
  },
  /**
   * @param {Function} sender
   * @param {number} userClassId
   * @param {number} workoutDay 1~n
   */
  submitDietComplete(sender, userClassId, workoutDay) {
    this.__log(sender, "web_submit_diet_complete", {
      "ucid": userClassId,
      "workout_day": workoutDay
    });
  },

  /**
   * 운동 일지 저장
   * @param {Function} sender
   * @param {number} userClassId
   * @param {number} workoutDay 1~n
   * @param {[String]} items 저장되는 MimeType 들
   */
  exerciseWriteLogComplete(sender, userClassId, workoutDay, items) {
    this.__log(sender, "web_exlog_write_complete", {
      "ucid": userClassId,
      "workout_day": workoutDay,
      "items": items
    });
  },

  /**
   * 운동 일지 시작
   * @param {Function} sender
   * @param {number} userClassId
   * @param {number} workoutDay 1~n
   */
  exerciseWriteLogBegin(sender, userClassId, workoutDay) {
    this.__log(sender, "web_exlog_write_begin", {
      "ucid": userClassId,
      "workout_day": workoutDay
    });
  },

  /**
   * 결제 완료
   *
   * @param {Function} sender
   * @param {String} transactionId 결제 전환 아이디
   * @param {GAProductCategory} itemCategory 상품 분류
   * @param {Number} itemId 상품 아이디
   * @param {String} itemName 상품명
   * @param {Number} price 상품가
   * @param {Number} discount 할인액
   * @param {String} couponName 쿠폰 이름
   * @param {number} couponAmount 쿠폰 적용 금액
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#purchase
   */
  ecommercePurchase(sender, transactionId, itemCategory,
                    itemId, itemName, price, discount,
                    couponName, couponAmount) {
    this.__log(sender, "purchase", {
      "transaction_id": transactionId,
      "currency": "KRW",
      "value": price - discount, // 판매가
      "items": [
        {
          "item_id" : itemId,
          "item_name": itemName,
          "item_category": itemCategory,
          "price": price,
          "discount": discount,
          "quantity": 1,
          "coupon": couponName,
          "coupon_amount": couponAmount
        }
      ],
      "env": this.__getEnv()
    });
  },


  /**
   * 결제 취소
   *
   * @param {Function} sender
   * @param {String} transactionId 결제 전환 아이디
   * @param {GAProductCategory} itemCategory 상품 분류
   * @param {number} itemId 상품 아이디
   * @param {String} itemName 상품명
   * @param {number} price 상품가
   * @param {number} discount 할인액
   */
  ecommerceCancelCheckout(sender,  transactionId, itemCategory,
                          itemId, itemName, price, discount) {
    this.__log(sender, "cancel_checkout", {
      "transaction_id": transactionId,
      "currency": "KRW",
      "value": price - discount, // 판매가
      "items": [
        {
          "item_id" : itemId,
          "item_name": itemName,
          "item_category": itemCategory,
          "price": price,
          "discount": discount,
          "quantity": 1
        }
      ],
      "env": this.__getEnv()
    });
  },

  /**
   * 결제 시작
   *
   * @param {Function} sender
   * @param {GAProductCategory} itemCategory 상품 분류
   * @param {Number} itemId 상품 아이디
   * @param {String} itemName 상품명
   * @param {Number} price 상품가
   * @param {Number} discount 할인액
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
   */
  ecommerceBeginCheckout(sender, itemCategory, itemId, itemName,
                         price, discount) {
    this.__log(sender, "begin_checkout", {
      "currency": "KRW",
      "value": price - discount, // 판매가
      "items": [
        {
          "item_id" : itemId,
          "item_name": itemName,
          "item_category": itemCategory,
          "price": price,
          "discount": discount,
          "quantity": 1
        }
      ],
      "env": this.__getEnv()
    });
  },

  /**
   * 상품 신청
   *
   * @param {Function} sender
   * @param {GAProductCategory} itemCategory 상품 분류
   * @param {Number} itemId 상품 아이디
   * @param {String} itemName 상품명
   * @param {Number} price 상품가
   * @param {Number} discount 할인액
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
   */
  ecommerceAddToCart(sender, itemCategory, itemId, itemName, price, discount) {
    this.__log(sender, "add_to_cart", {
      "currency": "KRW",
      "value": price - discount, // 판매가
      "items": [
        {
          "item_id" : itemId,
          "item_name": itemName,
          "item_category": itemCategory,
          "price": price,
          "discount": discount,
          "quantity": 1
        }
      ],
      "env": this.__getEnv()
    });
  },

  /**
   * 상품 조회
   * @param {Function} sender
   * @param {GAProductCategory} itemCategory 상품 분류
   * @param {Number} itemId 상품 아이디
   * @param {String} itemName 상품명
   * @param {Number} price 가격 ex) 1000.0
   * @param {Number} discount 할인액
   */
  ecommerceViewItem(sender, itemCategory, itemId, itemName, price, discount) {
    this.__log(sender, "view_item", {
      "currency": "KRW",
      "items": [
        {
          "item_id" : itemId,
          "item_name": itemName,
          "item_category": itemCategory,
          "price": price,
          "discount": discount,
          "quantity": 1
        }
      ],
      "env": this.__getEnv()
    });
  },

  /**
   * 목록에서 상품 선택
   * @param {Function} sender
   * @param {GAProductListCategory} category
   * @param {GAProductCategory} itemCategory
   * @param {Number} itemId 상품 아이디
   * @param {String} itemName 상품 이름
   */
  ecommerceSelectItem(sender,category, itemCategory, itemId, itemName) {
    let ecommerce = new GAEcommerceProductList(category);
    this.__log(sender, "select_item", {
      "item_list_id": ecommerce.id,
      "item_list_name": ecommerce.name,
      "items": [
        {
          "item_id" : itemId,
          "item_name": itemName,
          "item_category": itemCategory
        }
      ],
      "env": this.__getEnv()
    });
  },

  /**
   * 체형변화 사진 등록 완료
   * @param {Function} sender
   */
  submitNoonbodyComplete(sender) {
    this.__log(sender, "submit_noonbody_complete", null);
  },
  /**
   * 체형변화 사진 등록하기
   * @param {Function} sender
   */
  submitNoonbodyBegin(sender) {
    this.__log(sender, "submit_noonbody_begin", null);
  },
  /**
   * 프로필 수정하기
   * @param {Function} sender
   */
  clickMyProfileUpdate(sender) {
    this.__log(sender, "click_my_profile_update", null);
  },
  clickNaviProfile(sender) {
    this.__log(sender, "click_navi_profile", null);
  },
  /**
   * 프로필 클릭
   * @param {Function} sender
   */
  clickMyProfile(sender) {
    this.__log(sender, "click_my_profile", null);
  },
  /**
   * 내 프로그램 클릭
   * @param {Function} sender
   */
  clickMyProgram(sender) {
    this.__log(sender, "click_my_program", null);
  },
  /**
   * 내 일정 진입
   * @param {Function} sender
   */
  clickMySchedule(sender) {
    this.__log(sender, "click_my_schedule", null);
  },

  /**
   * 로그인
   * @param {Function} sender
   * @param {String} method 로그인 방식 apple | kakao | afreeca
   */
  login(sender, method) {
     this.__log(sender, "login", {
       "method": method,
       "env": this.__getEnv()
     });
  },

  /**
   * 가입
   * @param {Function} sender
   * @param {String} method
   */
  signUp(sender, method) {
    this.__log(sender, "sign_up", {
      "method": method,
      "env": this.__getEnv()
    });
  },


  /**
   * 홈페이지 띠배너 클릭
   * @param {Function} sender
   * @param {Number} bannerId
   * @param {String} bannerTarget
   */
  clickHomeTopBanner(sender, bannerId, bannerTarget) {
    this.__log(sender, "click_home_top_banner", {
      "id" : bannerId,
      "target": bannerTarget
    });
  },

  /**
   * 에드핏플러스 설문 클릭
   * @param sender
   */
  surveyPlusOpen(sender) {
    this.__log(sender, 'survey_plus_open',{ 'env': this.__getEnv() });
  },
  surveyPlusBegin(sender) {
    this.__log(sender, "survey_plus_begin", { "env": this.__getEnv() });
  },
  /**
   *
   * @param {Function} sender
   */
  surveyPlusComplete(sender) {
    this.__log(sender, "survey_plus_complete", { "env": this.__getEnv() });
  },
  /**
   * 에드핏플러스 진단설문 추천 프로그램 신청 클릭
   * @param {Function} sender
   * @param {Number} classId
   */
  surveyPlusCheckoutProgram(sender, classId) {
    this.__log(sender, "survey_plus_checkout", { "env": this.__getEnv(), "item_id": classId });
  },
  /**
   * @param {Function} sender
   */
  clickGuideForUser(sender) {
    this.__log(sender, "click_guide_for_user", null);
  },
  /**
   * @param {Function} sender
   */
  clickGuideForCoach(sender) {
    this.__log(sender, "click_guide_for_coach", null);
  },
  /**
   * @param {Function} sender
   */
  clickGuideForCenter(sender) {
    this.__log(sender, "click_guide_for_center", null);
  },
  
  
  // 앱으로 사용자 속성 GA 릴레이
  __setUserPropertyToApp(name, value) {
    if (!name || !value) return;
    let message = {
      command: 'setUserProperty',
      name: name,
      value: value
    };
    if (window.WebViewAnalytics) { // android
      window.WebViewAnalytics.postMessage(JSON.stringify(message));
    } else if (window.webkit
      && window.webkit.messageHandlers
      && window.webkit.messageHandlers.WebViewAnalytics) {
      window.webkit.messageHandlers.WebViewAnalytics.postMessage(message);
    } else {
      console.log("No native WebViewAnalytics API");
    }
  },
  
  
  // 앱으로 GA 릴레이
  __logEventToApp(name, params) {
    if (!name) return;
    let message = {
      command: 'logEvent',
      name: name,
      parameters: params ? JSON.stringify(params) : ''
    };
    if (window.WebViewAnalytics) { // android
      window.WebViewAnalytics.postMessage(JSON.stringify(message));
    } else if (window.webkit
      && window.webkit.messageHandlers
      && window.webkit.messageHandlers.WebViewAnalytics) {
      window.webkit.messageHandlers.WebViewAnalytics.postMessage(message);
    } else {
      console.log("No native WebViewAnalytics API");
    }
  },


  __getEnv() {
    let isAndroid = window.navigator.userAgent.indexOf('KeepFitOSa') >= 0;
    let isiOS = window.navigator.userAgent.indexOf('KeepFitOSi') >= 0;
    if (isAndroid) return 'aos';
    if (isiOS) return 'ios';
    return 'web';
  },

  /**
   * Analytics 이벤트 전송
   * @param {Function} sender GA sender
   * @param {String} event 이벤트 이름
   * @param {Object} data 키밸류 형식의 오브젝트
   */
  __log(sender, event, data) {
    try {
      if (GAReporter.debugMode == true) {
        console.log("GA", event, data);
      }
      if (this.__getEnv() !== 'web') {
        this.__logEventToApp(event, data);
        return;
      }
      if (data) sender('event', event, data);
      else sender('event', event);
    } catch (e) {
      console.log(e);
    }
  }
};

